   <!-- booking area -->
   <div class="booking-area pd-50" style="width: 100vw;">
    <div class="container">
        
        <div class="booking-form">
            <h4 *ngIf="data!.title" class="booking-title lazyload" style="color: #323232;
            font-weight: 500; font-display: swap;">{{data!.title}}</h4>
            <div class="filter-controls">
                <ul class="filter-btns" style="text-align: left !important; margin-top: 30px !important; margin-bottom: 20px !important;">
                    <li class="active" data-filter=".oneway" (click)="selectedTypeOption('One Way')">One Way</li>
                    <li data-filter=".roundtrip" (click)="selectedTypeOption('Round Trip')">Round Trip</li>
                </ul>
            </div>
            <div class="row filter-box">
                <div class="filter-item oneway">
                    <form action="#">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Pick Up Location</label>
                                <input #placesRef="ngx-places" [(ngModel)]="pickupOneWay" [options]='options' ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event , 1)" class="form-control" name="fromLoc" id="fromLoc" placeholder="From Location">
                                <i class="far fa-location-dot"></i>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Drop Off Location</label>
                                <input #placesRef="ngx-places" [(ngModel)]="dropOneWay" [options]='options' ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event , 2)" class="form-control" name="toLoc" id="toLoc" placeholder="To Location">
                                
                                <i class="far fa-location-dot"></i>
                            </div>
                        </div>                            
                    
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Pick Up Date</label>
                                <input matInput readonly    (click)="openPicker(1)"     (dateChange)="dateChanged(1, $event.value)"  style="background: white !important;" [min]="todayDate" class="form-control" name="pickupDate" id="pickupDate" placeholder="PickUp Date"  [matDatepicker]="picker" >
                                <mat-datepicker-toggle style="background: white !important;"  [for]="picker" style="top: 24px !important;    right: 24px !important;                                        
                                z-index: 0;
                                position: absolute; height: 55px;" ></mat-datepicker-toggle>
                                <mat-datepicker #picker style="background: white !important;"></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Pick Up Time</label>
                                <mat-select class="form-control custom-select" placeholder="PickUp Time" (selectionChange)="timeChanged(1, $event.value)"  style="position: relative;display: flex;">
                                      <mat-option *ngFor="let time of times" [value]="time">
                                        {{ time }}
                                      </mat-option>
                                    </mat-select>
                                <i class="far fa-clock"></i>
                            </div>
                        </div>
                        <div class="col-lg-9"></div>
                        
                        <div class="col-lg-3">
                        <div class="form-group">
                            <button class="theme-btn"  (click)="booking(1)" type="submit">Book Taxi<i
                                    class="fas fa-arrow-right"></i></button>
                        </div>
                        </div>
                    </div>
                    </form>
                </div>
                <div class="filter-item roundtrip">
                    <form action="#">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Pick Up Location</label>
                                <input #placesRef="ngx-places" [options]='options' ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event , 1)" 
                                class="form-control" name="fromLoc" id="fromLoc2" placeholder="From Location">
                                <i class="far fa-location-dot"></i>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Drop Off Location</label>
                                <input #placesRef="ngx-places" [options]='options' ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event , 2)" 
                                class="form-control" name="toLoc" id="toLoc2" placeholder="To Location">
                                <i class="far fa-location-dot"></i>
                            </div>
                        </div>                            
                    
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Pick Up Date</label>
                                <input matInput readonly   (click)="openPicker(2)"       (dateChange)="dateChanged(1, $event.value)"  style="background: white !important;" [min]="todayDate" 
                                class="form-control" name="pickupDate" id="pickupDate2" placeholder="PickUp Date"  [matDatepicker]="picker2" >
                                <mat-datepicker-toggle style="background: white !important;"  [for]="picker2" style="    right: 24px !important;
                                top: 23px !important;
                                z-index: 0;
                                position: absolute; height: 55px;" ></mat-datepicker-toggle>
                                <mat-datepicker #picker2 style="background: white !important;"></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Pick Up Time</label>
                                <mat-select class="form-control" placeholder="PickUp Time" (selectionChange)="timeChanged(1, $event.value)" style="position: relative;display: flex;">
                                    <mat-option *ngFor="let time of times" [value]="time">
                                      {{ time }}
                                    </mat-option>
                                  </mat-select>
                                  <i class="far fa-clock"></i>
                                </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Return Date</label>
                                <input matInput readonly (click)="openPicker(3)"  (dateChange)="dateChanged(2, $event.value)"  style="background: white !important;" [min]="returnDate" 
                                class="form-control" name="pickupDate2" id="pickupDate2" placeholder="Return Date"  [matDatepicker]="retPicker" >
                                <mat-datepicker-toggle style="background: white !important;"  [for]="retPicker" style="    right: 24px !important;
                                top: 23px !important;
                                z-index: 0;
                                position: absolute; height: 55px;" ></mat-datepicker-toggle>
                                <mat-datepicker #retPicker style="background: white !important;"></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Return Time</label>
                                <mat-select class="form-control" (selectionChange)="timeChanged(2, $event.value)" placeholder="Return Time"  style="position: relative;display: flex;">
                                    <mat-option *ngFor="let time of times" [value]="time">
                                      {{ time }}
                                    </mat-option>
                                  </mat-select>
                                <i class="far fa-clock"></i>
                            </div>
                        </div>
                        <div class="col-lg-9"></div>
                        
                        <div class="col-lg-2">
                        <div class="form-group">
                            <button class="theme-btn" (click)="booking(2)" type="submit">Book Taxi<i
                                    class="fas fa-arrow-right"></i></button>
                        </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- booking area end -->



<!-- taxi area -->
<div class="taxi-area py-120" #cabDtls>
<div class="container">
    <div class="row">
        <div class="col-lg-6 mx-auto">
            <div class="site-heading text-center">
                <span class="site-title-tagline">Our Taxi</span>
                <h2 class="site-title">Let's Check Available Taxi</h2>
                <div class="heading-divider"></div>
            </div>
        </div>
    </div>
    <!-- <div class="filter-controls">
        <ul class="filter-btns">
            <li class="active" data-filter="*">All Taxi</li>
            <li data-filter=".cat1">Hybrid Taxi</li>
            <li data-filter=".cat2">Town Taxi</li>
            <li data-filter=".cat3">Suv Taxi</li>
            <li data-filter=".cat4">Limousine Taxi</li>
        </ul>
    </div> -->
    <div class="row">
        <div class="col-md-6 col-lg-4" *ngFor="let car of cars; let position=index">
            <div class="taxi-item">
                <div class="taxi-img" height="200" width="200" >
                    
                    <img [ngSrc]="car?.image" loading="lazy" alt="car" style="position: sticky;" fill loading="eager">
                </div>
                <div class="taxi-content">
                    <div class="taxi-head">
                        <h4>{{car?.name}}</h4>
                        <span style="    background: #000000;
                        width: 100%;
                        display: block;
                        font-size: 1.2em;
                        border-radius: 16px;
                        font-weight: 900;
                        padding: 8px;">₹{{car?.traveltypedtl[travelSelectedPos].price}}/km - {{travelDtls.travelType}}</span><br>
                        
                    </div>
                    <div class="taxi-feature">
                        <ul>
                            
                            <li><i class="far fa-person-seat"></i> Passengers: <span>{{car?.personcount}}</span></li>
                            <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>{{car?.luggagecount}}</span></li>
                            <li><i class="far fa-car-side-bolt"></i> Total KMs: <span>{{travelDtls.actualKms}}</span></li>
                            <li><i class="far fa-car-side-bolt"></i> Billable KMs: <span>{{travelDtls.kms}}</span></li>
                            <li><i class="far fa-user-pilot"></i> Driver Beta: <span>₹{{car?.traveltypedtl[travelSelectedPos].driverbeta}} /Day * {{this.travelDtls.dayCount}} Days</span></li>
                            <!-- <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                            </li> -->
                            <li><i class="fas fa-indian-rupee-sign"></i> Total Cost: <span>₹{{car?.traveltypedtl[travelSelectedPos].price * travelDtls.kmsNum + car?.traveltypedtl[travelSelectedPos].driverbeta*this.travelDtls.dayCount}}</span></li>
                        </ul>
                    </div>
                    <a (click)="bookForm(position)" class="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></a>
                </div>
            </div>
        </div>                    
    </div>
</div>
</div>
<!-- taxi area end -->

<ng-template #dialogContent>
    <h1 mat-dialog-title style="color: #323232;font-weight: 700;
    font-size: medium;">Booking Confirmed</h1>
    <div style="text-align: center;
    position: relative;
    display: block;">
        <img src="../../assets/img/success.svg" loading="lazy" alt="success" style="height: 100px !important;">
    </div> 
    <div mat-dialog-content style="color: #323232;font-weight: 700;
    font-size: medium;">
      <p>Your Booking Id: {{resultId}}. You will receive mail shortly.</p>
    </div>
    <div mat-dialog-actions style="text-align: center;
    position: relative;
    display: block;">
      <button mat-button [mat-dialog-close]="true" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium;
      ">OK</button>
    </div>
  </ng-template>

  <ng-template #errorDialogContent>
    <h1 mat-dialog-title>Error</h1>
    <div mat-dialog-content>
      <p>{{errorMessage}}</p>
    </div>
    <div mat-dialog-actions style="text-align: center;
    position: relative;
    display: block;">
      <button mat-button [mat-dialog-close]="false">OK</button>
    </div>
  </ng-template>

  <ng-template #bookingDialogContent>
   <!--Booking area-->
<div class="user-profile pd-50" >
    <div class="container">
        <div class="row">
           
            <div class="col-lg-12">
                <div class="user-profile-wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="user-profile-card">
                                <h4 class="user-profile-card-title">Travel Info</h4>
                                <div class="user-profile-form">
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Name</label>
                                                    <input type="text" class="form-control" [(ngModel)]="travelDtls.name" name="name" id="name" placeholder="Your Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Phone</label>
                                                    <input type="number" class="form-control" maxlength="10" [(ngModel)]="travelDtls.phoneNumber" name="number" id="number" placeholder="Phone Number">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input type="email" class="form-control" [(ngModel)]="travelDtls.email" name="email" id="email" placeholder="Email">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>From Location</label>
                                                    <input type="text" class="form-control" disabled [ngModel]="travelDtls.pickupLocName" name="s-destination" id="s-destination" placeholder="Start Destination">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Pick Up Date & Time</label>
                                                    <input type="text" class="date-pick form-control" disabled [ngModel]="travelDtls.pickupDate" name="date-pick" id="date-pick" placeholder="Select Date">
                                                </div>
                                            </div>

                                           
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Selected Cab</label>
                                                    <input type="text" class="form-control" disabled [ngModel]="travelDtls.selectedCabDtl?.name" name="selected-cab" id="selected-cab" placeholder="Select Date">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Drop Location</label>
                                                    <input type="text" class="form-control" disabled [ngModel]="travelDtls.dropLocName" name="e-destination" id="e-destination" placeholder="End Destination">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group" *ngIf="travelDtls.travelType=='Round Trip'">
                                                    <label>Return Date & Time</label>
                                                    <input type="text" class="date-pick form-control" disabled [ngModel]="travelDtls.returnDate" name="date-return" id="date-return" placeholder="Select Date">
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <input placeholder="Address" name="message" [(ngModel)]="travelDtls.address" id="message" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-md-9">
                                            <button type="button" (click)="confirm()" class="theme-btn my-2 col-md-5"> Confirm Booking</button>
                                        <button type="button" style="margin-left: 10px; margin-right: 10px;"   [mat-dialog-close]="true" (click)="modify()" class="theme-btn my-2 col-md-2">Modify</button>
                                        <button type="button" [mat-dialog-close]="true" class="theme-btn my-2 col-md-2"> Cancel</button>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Booking area end-->
  </ng-template>

  <ng-template #otpContent>
    <h1 mat-dialog-title style="color: #323232;font-weight: 700;
    font-size: medium;">Please enter the OTP</h1>
    
    <div mat-dialog-content style="color: #323232;font-weight: 700;
    font-size: medium;">
      <input [(ngModel)]="otp" type="text" max="6"   placeholder="otp"/>
      <label *ngIf="isOTPError" style="color: red;">Please enter valid OTP</label>
    </div>
    <div mat-dialog-actions style="text-align: center;
    position: relative;
    display: block;">
      <button mat-button (click)="verify()" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium;
      ">Verify</button>
      <button mat-button [mat-dialog-close]="false" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium; margin-left: 5px
      ">Cancel</button>
    </div>
  </ng-template>

  <ng-template #phoneContent>
    <h1 mat-dialog-title style="color: #323232;font-weight: 700;
    font-size: medium;">Please re-enter the Phone Number</h1>
    
    <div mat-dialog-content style="color: #323232;font-weight: 700;
    font-size: medium;">
      <input [(ngModel)]="phone" type="number" placeholder="Phone"/>
      <label *ngIf="isPhoneError" style="color: red;">{{errorMobile}}</label>
    </div>
    <div mat-dialog-actions style="text-align: center;
    position: relative;
    display: block;">
      <button mat-button (click)="verifyPhone()" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium;
      ">Verify</button>
      <button mat-button [mat-dialog-close]="false" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium; margin-left: 5px;
      ">Cancel</button>
    </div>
  </ng-template>
  <div
id="recaptcha-container"
class="justify-center flex"
siteKey="6LfRXdkpAAAAAIzL3jaQY-IyWQNl-ZJKOOPdBRRQ"
></div>