import { CarModel } from "./car.model";

export class TravelModel{
    pickupLocName? : string;
    pickupLocNameFull? : string;
    pickupDtlName?: string;
    pickupLocLat? : number;
    pickupLocLon? : number;
    pickupDate? : string;
    pickupTime? : string;
    dropLocName? : string;
    dropLocNameFull? : string;
    dropLocLat? : number;
    dropLocLon? : number;
    travelType : string;
    returnDate : string;
    returnTime : string;
    arrivalTime : string;
    selectedCabDtl: CarModel;
    kms: string;
    kmsNum: number;
    duration: string;
    name: string;
    phoneNumber: string;
    address: string;
    email: string;
    dayCount: number;
    actualKms: string;
}