import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from '../shared/Shared.Module';

import { RecaptchaVerifier, getAuth, signInWithEmailAndPassword, signInWithPhoneNumber } from 'firebase/auth';
import { Observable } from 'rxjs';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { TravelModel } from '../model/travel.model';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { isPlatformBrowser } from '@angular/common';
import { AppConstants } from '../shared/app.constants';
declare var $:any;
@Component({
  selector: 'app-booking',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.scss'
})
export class BookingComponent implements OnInit, AfterViewInit {
  
  cars: any;
  errorMessage: string;  
  travelSelectedPos: number = 0;
  travelDtls:TravelModel = new TravelModel();
  resultId: string="";
  isOTPError: boolean =false;
  isPhoneError : boolean = false;
  options = {
    types: ["geocode"],
    componentRestrictions:{
      country:["IN"],
      // strictBounds: true
    }
  };
  
  times : string[] = [];
  selectedTime : string = '';
  returnDate: Date = new Date();
  todayDate : Date = new Date();
  currentHour : number = -1;
  dropOneWay: string;
  pickupOneWay: string;
  @ViewChild('cabDtls') cabDtls : ElementRef;
  @ViewChild('ngxPlaces') placesRef : NgxGpAutocompleteDirective;
  @ViewChild('picker') picker!: MatDatepicker<Date>;
  @ViewChild('picker2') picker2!: MatDatepicker<Date>;
  @ViewChild('retPicker') retPicker!: MatDatepicker<Date>;
  @ViewChild('dialogContent') dialogContent: TemplateRef<any>;
@ViewChild('errorDialogContent') errorDialogContent: TemplateRef<any>;
@ViewChild('bookFormSection') bookFormSection: ElementRef;
@ViewChild('bookingDialogContent') bookingDialogContent: TemplateRef<any>;
@ViewChild('otpContent') otpContent: TemplateRef<any>;
@ViewChild('phoneContent') phoneContent: TemplateRef<any>;
otp: any;
confirmationResult: any ;
trackingId: any;
phone:any;
verifier: any;
errorMobile: string;
data: any = {};
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone, private el:ElementRef, private dialog: MatDialog, private router: Router, private gtmService: GoogleTagManagerService)
  {
    
  }
  ngAfterViewInit(): void {

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('../booking.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
       this.data=data;                            
      };
      worker.postMessage(this.data);  
      
    } else {
      
    }
    this.gtmService.googleTagManagerId='GTM-M3BV5W63';
 
    this.travelDtls.travelType = "One Way";
   if(isPlatformBrowser(this.platformId))
   { 
      var defaultFilter = '.oneway'; // Adjust as needed
      $(".filter-box").isotope({ filter: defaultFilter });
      
      //this.generate24HourList();
      $('.user-profile').hide();
      $('.taxi-area').hide();
      
      // this.travelDtls.pickupLocName = "kanyakumari";
      // this.travelDtls.dropLocName = "salem";
      if(window.location.pathname.startsWith('/one-way-drop-taxi')){
        this.getCars(this.travelDtls.pickupLocName);
      }
    }
    this.tracking();
  }
  ngOnInit(): void {
    
  }
  getDistance(origin: string, destination: string): Observable<any> {

    var travel = new Date();    
    const request:google.maps.DirectionsRequest = {
      origin: origin, 
      destination: destination,
       travelMode: google.maps.TravelMode.DRIVING,
      // drivingOptions: {
      //   departureTime:travel,
      //   trafficModel: google.maps.TrafficModel.BEST_GUESS
      // }
    };
    
    var directionService = new google.maps.DirectionsService();
    directionService.route(request,(result, status)=>{
      if (status === 'OK') {
          
          this.travelDtls.duration = result.routes[0].legs[0].duration.text;
          this.travelDtls.kms = result.routes[0].legs[0].distance.text;       
          travel.setSeconds(travel.getSeconds()+result.routes[0].legs[0].duration.value);
          this.travelDtls.arrivalTime =    new Date(travel.getTime()).toLocaleString();
          this.travelDtls.kmsNum = Number(result.routes[0].legs[0].distance.text.replace("km","").replace(",",""));   
          this.travelDtls.dayCount = 1;      
          this.travelDtls.actualKms = result.routes[0].legs[0].distance.text;
          if(this.travelDtls.travelType == "One Way")
          {
            if(this.travelDtls.kmsNum<=130)
            {
              this.travelDtls.kmsNum=130;
              this.travelDtls.kms="130 km";
            }
            
          }
          else{
            var dateDiff = this.getDiffDays(this.travelDtls.pickupDate, this.travelDtls.returnDate)+1;
            this.travelDtls.actualKms = (Number(result.routes[0].legs[0].distance.text.replace(' km',''))*2).toString();
            this.travelDtls.kmsNum = this.travelDtls.kmsNum*2;
            this.travelDtls.kms=this.travelDtls.kmsNum+" km"; 
            this.travelDtls.dayCount = dateDiff;
            if(this.travelDtls.kmsNum<=(dateDiff * 250))
            {
              this.travelDtls.kmsNum=(dateDiff * 250);
              this.travelDtls.kms=(dateDiff * 250)+" km";
            }
          }
          // console.log(this.cars)
          $('.taxi-area').show();
          setTimeout(() => {
            $('.preloader').fadeOut();
            $('.booking-area').animate({    }, 1000, 'swing', function(){
              $('.user-profile').hide();
              $('html, body').animate({
                scrollTop: $('.taxi-area').offset().top
              }, 100);
              
              
            });            
        }, 100);
      }
      else{
        
          
          $('.preloader').fadeOut();
          $('.user-profile').fadeIn().hide();
                
      }
    });

    
    return null;
  }
  modify(){
   
    $('.preloader').show();
    
      $('html, body').animate({
        scrollTop: $('.booking-area').offset().top
      }, 1000);
      $('.taxi-area').hide();
      
      $('.preloader').hide();
    
    
  }
  bookForm(position){
    $('.preloader').show();
    this.travelDtls.selectedCabDtl = this.cars[position];
    
    this.openBookingDialog();
    $('.preloader').hide();
    
    
  }

  
  getDiffDays(sDate, eDate) {
    var startDate = new Date(sDate);
    var endDate = new Date(eDate);
  
    var Time = endDate.getTime() - startDate.getTime();
    return Time / (1000 * 3600 * 24);
  }
  getSelectedType(carNum: number){    
    return this.cars[carNum].TravelTypeDtl.find(x=>x.TravelModeDtl==this.travelDtls.travelType);
  }
  
  async confirm(){
    
    
    if(!this.travelDtls.name)
    {
      this.errorMessage="Please fill your name";
      this.openErrorDialog();
      return;
    }
    else if(!this.travelDtls.phoneNumber){
      this.errorMessage="Please fill your mobile number";
      this.openErrorDialog();
      return;
    }
    else if(this.travelDtls.phoneNumber.toString().length!=10){
      this.errorMessage="Please enter 10 digits mobile number";
      this.openErrorDialog();
      return;
    }
    else if(!this.travelDtls.email){
      
      this.errorMessage="Please fill your email";
      this.openErrorDialog();
      return;
    }
    else if(!new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$').test(this.travelDtls.email))
    {
      this.errorMessage="Please enter valid email";
      this.openErrorDialog();
      return;
    }
    else if(!this.travelDtls.address){
      this.errorMessage="Please fill your address";
      this.openErrorDialog();
      return;
    }
    this.openPhoneDialog()
  }
  generate24HourList(): string[] {
    $('.mat-mdc-select-arrow-wrapper').hide();
    const timesArr: string[] = [];
    for (let i = this.currentHour+1; i < 24; i++) {

      for (let j = 0; j < 60; j += 30) {
        
        let temp =i;
        let ampm = "";        
        if(temp >= 12)
        {
            ampm = " PM"
            if(temp >= 13){
              temp=temp-12          
            }
        }
        else{
          ampm = " AM";
        }
        const hourString = ('0' + temp).slice(-2);
        const minuteString = ('0' + j).slice(-2);
        const timeString = `${hourString}:${minuteString} ${ampm}`;
        timesArr.push(timeString);
      }
    }
    return timesArr;
  }
  
  booking(bookingType){
    if(bookingType ==1)
    {
      if(this.travelDtls && this.travelDtls.pickupLocName && this.travelDtls.dropLocName && this.travelDtls.pickupDate && this.travelDtls.pickupTime)
      {
      $('.preloader').show();
    
      this.getDistance(this.travelDtls.pickupLocName, this.travelDtls.dropLocName);
      }
      else{
        this.errorMessage="Please fill all the fields";
        this.openErrorDialog();

      }
    }
    else{
      if(this.travelDtls && this.travelDtls.pickupLocName && this.travelDtls.dropLocName && this.travelDtls.pickupDate && this.travelDtls.pickupTime
        && this.travelDtls.returnDate && this.travelDtls.returnTime)
      {
      $('.preloader').show();
    
    this.getDistance(this.travelDtls.pickupLocName, this.travelDtls.dropLocName);
      }
      else{
        this.errorMessage="Please fill all the fields";
        this.openErrorDialog();        
      }
    }
    
    
  }
  openDialog(resultId): void {
    this.dialog.closeAll()
    this.router.navigateByUrl("/bookingSuccess",{state: {'resultId': resultId}});
    // const dialogRef = this.dialog.open(this.dialogContent, {
    //   disableClose:true
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if(result)      
    //   {
    //     // window.scrollTo(0,0); 
        
    //     $("html, body").animate({ scrollTop: 0 }, "slow", function(){
    //       window.location.reload();
    //     });
    //   }
    //   // console.log('Dialog closed with result:', result);
    // });
    
  }
  openErrorDialog(){
    const dialogRef = this.dialog.open(this.errorDialogContent, {
      disableClose:true
    });
    dialogRef.afterClosed().subscribe(result => {
      
      // console.log('Dialog closed with result:', result);
    });
  }
  openBookingDialog(){
    const gtmTag = {
      event: 'bookingInitiated',
      pageName: 'bookingInitiated',
  
      };
      this.gtmService.getDataLayer().push(gtmTag);
    const dialogRef = this.dialog.open(this.bookingDialogContent, {
      disableClose:true,
      height: '100%',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)      
      {
        
      }
      // console.log('Dialog closed with result:', result);
    });
    
  }
  public handleAddressChange(place: google.maps.places.PlaceResult, result : number) {
    
    // Do some stuff
    if(result == 1)
    {
      this.travelDtls.pickupLocName = place.address_components![0].long_name;
      this.travelDtls.pickupLocLat = place.geometry?.location?.lat();
      this.travelDtls.pickupLocLon = place.geometry?.location?.lng();            
      this.travelDtls.pickupLocNameFull = place.formatted_address;
      //this.downloadFirectionebaseContent('price.json',1);
      this.getCars(this.travelDtls.pickupLocName);
    }
    else{
      this.travelDtls.dropLocName = place.address_components![0].long_name;
      this.travelDtls.dropLocLat = place.geometry?.location?.lat();
      this.travelDtls.dropLocLon = place.geometry?.location?.lng();
      this.travelDtls.dropLocNameFull = place.formatted_address;
    }
  }
  getCars(loc){
    let jsonPayload1={"action":"fetchcars", city:loc
  }
 
  fetch(AppConstants.APP_URL+"?userid=123",{method:'POST',
  body:JSON.stringify(jsonPayload1)}).then((response) => response.json())
  .then((resData) =>{ 
    this.cars=resData;

    this.hideLoader();
  });
  }

  openPicker(position: number) {
    if(position == 1)
    {
      this.picker.open();
    }
    else if(position == 2)
    {
      
        this.picker2.open();
      
    }
    else{
      if(this.travelDtls.pickupDate)
        this.retPicker.open();
    }
  }
  timeChanged(type: number, value){
    if(type == 1)
    {
      this.travelDtls.pickupTime = value;
    }
    else{
      this.travelDtls.returnTime = value;
    }
  }
  dateChanged(selection: any, value: any){    
    if(selection == 1)
    {
      
      this.returnDate.setTime(value.getTime());      
      this.travelDtls.pickupDate = new Date(Date.parse(value)).toDateString();
    }
    else{
      this.travelDtls.returnDate= new Date(Date.parse(value)).toDateString();
    }
    if(this.travelDtls.pickupDate == new Date().toDateString())
    {
      this.currentHour= Number(new Date().toLocaleTimeString("en-GB", { hour12: false, hour: '2-digit', minute: '2-digit' }).split(":")[0]);    
    }
    else{
      this.currentHour = -1;
    }
    if(selection == 2)
    {
      
      this.currentHour = -1;
    }
    this.times = this.generate24HourList();
  }
  selectedTypeOption(selected: string)
  {
    this.travelDtls.travelType = selected;
    if(selected == "One Way")
    {
      this.travelSelectedPos  = 0;
    }
    else{
      this.travelSelectedPos  = 1;
    }
  }

  tracking(){
    const auth = getAuth();
  
    this.verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      'callback': (response) => {
        // console.log(response)
        
        
        this.hideLoader();
      },
      'expired-callback': () => {      
      }
    });
    this.verifier.verify();
   }
   verifyPhone(){
    if(this.phone && this.phone.toString().length==10)
    {
      if(this.phone == this.travelDtls.phoneNumber)
      {
        this.sendOTP( this.verifier, "+91"+this.phone)
      }
      else{
        this.errorMobile = "Mobile number mismatch"
        this.isPhoneError = true;  
      }
    }
    else{
      this.isPhoneError = true;
      this.errorMobile = "Please enter valid mobile number"
    }
   }
   openPhoneDialog(){
    
    const dialogRef = this.dialog.open(this.phoneContent, {
      disableClose:true,
      backdropClass: 'custom-dialog-overlay',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)      
      {
        
      }
    });
   }
   openOTPDialog(): void {
    
    const dialogRef = this.dialog.open(this.otpContent, {
      disableClose:true,
      backdropClass: 'custom-dialog-overlay',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)      
      {
        
      }
    });
    
  }
  verify()
  {
    
    $('.preloader').show();
    this.confirmationResult.confirm(this.otp).then(async (res) => {
      // User signed in successfully.
      const user = res.user;
      this.dialog.closeAll();
      // console.log(user.stsTokenManager.refreshToken);
      try {
     
          // console.error(JSON.stringify(res));
          let travelDtl = this.travelDtls.selectedCabDtl.traveltypedtl.find(x=>x.travelmode == this.travelDtls.travelType);

          let json = {
            action:"create_trip",
            token:user.stsTokenManager.refreshToken,
            name: this.travelDtls.name,
          phone: this.travelDtls.phoneNumber,
          email: this.travelDtls.email,
          pickUpDate: this.travelDtls.pickupDate,
          pickUpLoc: this.travelDtls.pickupLocName,
          pickUpLocFull: this.travelDtls.pickupLocNameFull,
          pickUpTime: this.travelDtls.pickupTime,
          dropLoc: this.travelDtls.dropLocName,
          dropLocFull: this.travelDtls.dropLocNameFull,      
          travelType: this.travelDtls.travelType,
          kms: this.travelDtls.kms,
          returnTime: this.travelDtls.returnTime ? this.travelDtls.returnTime : '',
          returnDate: this.travelDtls.returnDate ? this.travelDtls.returnDate : '',
          driverBeta: travelDtl.driverbeta * this.travelDtls.dayCount,
          price: ((travelDtl.driverbeta * this.travelDtls.dayCount)+ (travelDtl.price * this.travelDtls.kmsNum))+' Approximate price',
          cab: this.travelDtls.selectedCabDtl.name,
          address: this.travelDtls.address,
          createdDate: new Date().toLocaleString(),
          status:1,
          dayCount: this.travelDtls.dayCount,
          pricePerKms: travelDtl.price
          };
          fetch('https://onedropcab.com/api.php',{
            method:'POST',
            body:JSON.stringify(json)}).then((response) => response.json())
          .then((resData) => {
            this.resultId=resData.resultid;
            this.openDialog(this.resultId);
          });
        //   let val= (await getCountFromServer(collection(getFirestore(),"onedropcab.in"))).data().count+1; 
        //   const result = setDoc(doc(getFirestore(),"onedropcab.in", "ODRCBID00"+(val)),{
        //   name: this.travelDtls.name,
        //   phone: this.travelDtls.phoneNumber,
        //   email: this.travelDtls.email,
        //   pickUpDate: this.travelDtls.pickupDate,
        //   pickUpLoc: this.travelDtls.pickupLocName,
        //   pickUpLocFull: this.travelDtls.pickupLocNameFull,
        //   pickUpTime: this.travelDtls.pickupTime,
        //   dropLoc: this.travelDtls.dropLocName,
        //   dropLocFull: this.travelDtls.dropLocNameFull,      
        //   travelType: this.travelDtls.travelType,
        //   kms: this.travelDtls.kms,
        //   returnTime: this.travelDtls.returnTime ? this.travelDtls.returnTime : '',
        //   returnDate: this.travelDtls.returnDate ? this.travelDtls.returnDate : '',
        //   driverBeta: travelDtl.DriverBeta * this.travelDtls.dayCount,
        //   price: ((travelDtl.DriverBeta * this.travelDtls.dayCount)+ (travelDtl.Price * this.travelDtls.kmsNum))+' Approximate price',
        //   cab: this.travelDtls.selectedCabDtl.Name,
        //   address: this.travelDtls.address,
        //   id: "ODRCBID00"+(val),
        //   createdDate: new Date().toLocaleString(),
        //   status:1,
        //   dayCount: this.travelDtls.dayCount,
        //   pricePerKms: travelDtl.Price
        // }
        
        // );
        
        
       
      }catch (e) {
        // console.error("Error adding document: ", e);
      } 
    }).catch((error) => {
     this.hideLoader();
     this.isOTPError =true;
    });
  }
   sendOTP( recaptchaVerifier, phone){
    try{
      signInWithPhoneNumber(getAuth(), phone, recaptchaVerifier)
      .then(async (confirmationResult) => 
      {
        // console.log(confirmationResult);
        this.confirmationResult = confirmationResult;
        this.openOTPDialog();
        this.hideLoader()
      },
      err=>{
        // console.log(err);
        this.hideLoader()
      }
      
       );
      
    }catch(err){
      // console.error(err);
    }
   }
   hideLoader(){

    setTimeout(() => {
        $('.preloader').fadeOut();
    }, 50);
  }
}
